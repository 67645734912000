<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="txt-black txt-16">
    <p class="translation-template-variable-error-part-1">
      {{ $t('components.notifications.invalidFilePleaseCorrectErrors') }}
    </p>
    <ul class="pl-20">
      <li class="txt-font-din-bold row-indexes-list-item">{{ $tc('components.labels.row', rowIndexes.length) }}: {{ rowIndexes.join(', ') }}</li>
    </ul>
    <p v-html="$t('components.notifications.translationTemplateVariableErrors')"></p>
  </div>
</template>

<script>
export default {
  props: {
    rowIndexes: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style>
.translation-template-variable-error-part-1 {
  margin-top: 1rem;
}
.black-code {
  color: #0a7700;
}
.row-indexes-list-item {
  font-size: 2rem;
}
</style>
